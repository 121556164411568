import React, { FC, MouseEvent, useReducer, ChangeEvent } from "react";
import { isEmailValid } from "@/utils/validation";
import {
  addContactToTheList,
  alreadySubscribedStatusCode,
} from "@/services/mailingListService";
import { Popup } from "@/components/basic/Popup";
import { FooterForm } from "@/components/FooterForm";
import {
  successTitle,
  failureTitle,
  successNewsletterMessage as successMessage,
  footerFailureNewsletterMessage as failureMessage,
} from "@/utils/popupMessages";
import {
  ActionType,
  initialState,
  footerFormReducer,
} from "@/reducers/footerFormReducer";

export const FooterFormWrapper: FC = () => {
  const [
    {
      isEmailEnabled,
      email,
      isMailingListSubscriber,
      showSuccessMessage,
      showFailureMessage,
      showMarketingAgreements,
      showLoadingMessage,
    },
    dispatch,
  ] = useReducer(footerFormReducer, initialState);

  const handleEmailInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch({
      type: ActionType.inputChange,
      payload: { email: value, isEmailEnabled: isEmailValid(value) },
    });
  };

  const handleMailingListCheckboxChange = () => {
    dispatch({ type: ActionType.checkboxChange });
  };

  const handleEmailInputClick = () => {
    dispatch({ type: ActionType.inputClick });
  };

  const subscribeToMailingList = async () => {
    try {
      await addContactToTheList(email);
      dispatch({ type: ActionType.setSuccessMessage });
    } catch (error) {
      error.response.status === alreadySubscribedStatusCode
        ? dispatch({ type: ActionType.setSuccessMessage })
        : dispatch({ type: ActionType.setFailureMessage });
    }
  };

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch({ type: ActionType.submitStart });
    await subscribeToMailingList();
    dispatch({ type: ActionType.submitFinish });
  };

  const closePopup = () => {
    dispatch({ type: ActionType.closePopup });
  };

  return (
    <>
      <Popup
        title={showFailureMessage ? failureTitle : successTitle}
        messages={[
          !showLoadingMessage &&
            (showSuccessMessage ? successMessage : failureMessage),
        ]}
        isOpen={showLoadingMessage || showSuccessMessage || showFailureMessage}
        handleClose={closePopup}
        variant={showFailureMessage ? "failure" : "success"}
        showIcon={!showFailureMessage}
      />
      <FooterForm
        disabled={!isEmailEnabled || !isMailingListSubscriber}
        handleEmailInputChange={handleEmailInputChange}
        handleMailingListCheckboxChange={handleMailingListCheckboxChange}
        submit={handleSubmit}
        handleEmailInputClick={handleEmailInputClick}
        showMarketingAgreements={showMarketingAgreements}
        email={email}
      />
    </>
  );
};
