import React, { FC } from "react";

export interface LinkProps {
  url?: string;
  text: string;
  classname?: string;
}
export const Link: FC<LinkProps> = ({ url, text, classname }) => (
  <a className={classname} href={url}>
    {text}
  </a>
);
