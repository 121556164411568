import React, { FC } from "react";

export interface SocialMediaItemProps {
  url: string;
  iconUrl: string;
  alt: string;
  classname?: string;
  iconWidth?: string;
  iconHeight?: string;
}

export const SocialMediaItem: FC<SocialMediaItemProps> = ({
  url,
  iconUrl,
  alt,
  classname,
  iconWidth,
  iconHeight,
}) => (
  <li className={classname}>
    <a href={url}>
      <img
        className="social-media-item"
        alt={alt}
        src={iconUrl}
        width={iconWidth}
        height={iconHeight}
      />
    </a>
  </li>
);
