import React, { FC } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "@/components/basic/Link";

export interface FooterLawLinksProps {
  showCookiesForm: () => void;
}

export const FooterLawLinks: FC<FooterLawLinksProps> = ({
  showCookiesForm,
}) => {
  const data: GatsbyTypes.Query = useStaticQuery(graphql`
    {
      allContentfulFooterLinksLaw(sort: { fields: order }) {
        edges {
          node {
            url
            text
            order
            id
          }
        }
      }
    }
  `);

  return (
    <ul className="l-9-cols-mobile">
      <li className="color-primary footer-link-law">
        &copy; {new Date().getFullYear()} Anixe All rights reserved
      </li>
      {data.allContentfulFooterLinksLaw.edges.map(({ node }) => (
        <li className="footer-link-law" key={node.text}>
          {node.id === process.env.COOKIE_POLICY_ID ? (
            <button
              className="color-primary footer-link-law"
              onClick={showCookiesForm}
            >
              {node.text}
            </button>
          ) : (
            <Link
              url={node.url}
              text={node.text}
              classname="color-primary"
              key={node.text}
            />
          )}
        </li>
      ))}
    </ul>
  );
};
