import React, { FC } from "react";
import { Tooltip } from "@/components/basic/Tooltip";

export const MailingListTooltip: FC = () => (
  <Tooltip>
    Remember that you can change your decision at any time.&nbsp;Detailed
    information on the scope and manner of data processing can be found in
    the&nbsp;
    <a className="text-underline" href="/privacy">
      Privacy Policy
    </a>
    . You can withdraw your consent at any time by clicking the unsubscribe link
    at the bottom of each email or by contacting us at&nbsp;
    <a className="text-underline" href="mailto: privacy@anixe.pl">
      privacy@anixe.pl
    </a>
    . The withdrawal of consent shall not affect lawfulness of processing based
    on consent before its withdrawal.
  </Tooltip>
);
