import { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";

const portalRoot =
  typeof document !== `undefined` ? document.getElementById("portal") : null;

export const Portal: FC = ({ children }) => {
  const [el, setEl] = useState(null);

  useEffect(() => {
    const el =
      typeof document !== `undefined` ? document.createElement("div") : null;
    setEl(el);
    portalRoot.appendChild(el);
    return () => {
      portalRoot.removeChild(el);
    };
  }, []);

  if (el) {
    return ReactDOM.createPortal(children, el);
  } else {
    return null;
  }
};
