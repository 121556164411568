export enum ActionType {
  inputChange = "INPUT_CHANGE",
  checkboxChange = "CHECKBOX_CHANGE",
  inputClick = "INPUT_CLICK",
  setSuccessMessage = "SET_SUCCESS_MESSAGE",
  setFailureMessage = "SET_FAILURE_MESSAGE",
  closePopup = "CLOSE_POPUP",
  submitStart = "SUBMIT_START",
  submitFinish = "SUBMIT_FINISH",
}

export const initialState = {
  isEmailEnabled: false,
  email: "",
  isMailingListSubscriber: false,
  showSuccessMessage: false,
  showFailureMessage: false,
  showMarketingAgreements: false,
  showLoadingMessage: false,
};

export const footerFormReducer = (state, action) => {
  switch (action.type) {
    case ActionType.inputChange:
      return {
        ...state,
        email: action.payload.email,
        isEmailEnabled: action.payload.isEmailEnabled,
      };
    case ActionType.checkboxChange:
      return {
        ...state,
        isMailingListSubscriber: !state.isMailingListSubscriber,
      };
    case ActionType.inputClick:
      return {
        ...state,
        showMarketingAgreements: true,
      };
    case ActionType.setSuccessMessage:
      return {
        ...state,
        showSuccessMessage: true,
      };
    case ActionType.setFailureMessage:
      return {
        ...state,
        showFailureMessage: true,
      };
    case ActionType.closePopup:
      return {
        ...state,
        showLoadingMessage: false,
        showSuccessMessage: false,
        showFailureMessage: false,
      };
    case ActionType.submitStart:
      return {
        ...state,
        isEmailEnabled: false,
        showSuccessMessage: false,
        showFailureMessage: false,
        showLoadingMessage: true,
      };
    case ActionType.submitFinish:
      return {
        ...state,
        email: "",
        isMailingListSubscriber: false,
        showMarketingAgreements: false,
        showLoadingMessage: false,
      };
    default:
      throw new Error();
  }
};
