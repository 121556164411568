import React, { FC } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "@/components/basic/Link";

export const FooterLinkColumns: FC = () => {
  const data: GatsbyTypes.Query = useStaticQuery(graphql`
    {
      allContentfulFooterLinksSitemap(sort: { fields: order }) {
        edges {
          node {
            url
            text
            order
          }
        }
      }
      allContentfulFooterLinksSpecialisation(sort: { fields: order }) {
        edges {
          node {
            url
            text
            id
          }
        }
      }
      allContentfulFooterLinksCompany(sort: { fields: order }) {
        edges {
          node {
            url
            text
            order
          }
        }
      }
    }
  `);

  return (
    <div className="l-8-cols-desktop l-bleed">
      <div className="footer-links clearfix">
        <div className="l-4-cols-tablet l-bleed-before">
          <strong className="heading l-push-bottom">Sitemap</strong>
          <ul>
            {data.allContentfulFooterLinksSitemap.edges.map(({ node }) => (
              <li key={node.text} className="footer-link">
                <Link
                  url={`${node.url}#intro`}
                  text={node.text}
                  classname="color-primary"
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="l-4-cols-tablet l-bleed-before">
          <strong className="heading l-push-bottom">Our Specialisation</strong>
          <ul>
            {data.allContentfulFooterLinksSpecialisation.edges.map(
              ({ node }) => (
                <li key={node.text} className="footer-link">
                  <Link
                    url={`${node.url}#intro`}
                    text={node.text}
                    classname="color-primary"
                  />
                </li>
              )
            )}
          </ul>
        </div>
        <div className="l-4-cols-tablet l-bleed-before">
          <strong className="heading l-push-bottom">Company</strong>
          <ul>
            {data.allContentfulFooterLinksCompany.edges.map(({ node }) => (
              <li key={node.text} className="footer-link">
                <Link
                  url={`${node.url}#intro`}
                  text={node.text}
                  classname="color-primary"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
