import React, { FC } from "react";
import { FooterFormWrapper } from "@/components/FooterFormWrapper";
import { FooterIntro } from "@/components/FooterIntro";
import { FooterLinkColumns } from "@/components/FooterLinkColumns";
import { FooterLawLinks } from "@/components/FooterLawLinks";
import { FooterSocialMedia } from "@/components/FooterSocialMedia";
import { FooterCompanyLocations } from "@/components/FooterCompanyLocations";

export interface FooterProps {
  showCookiesForm: () => void;
}

export const Footer: FC<FooterProps> = ({ showCookiesForm }) => (
  <footer className="site-footer">
    <div className="site-footer-content">
      <div className="color-primary l-wrapper clearfix">
        <hr className="divider d-block l-full"></hr>
        <FooterIntro />
        <hr className="divider divider-contact d-block l-full"></hr>
        <FooterLinkColumns />
      </div>
      <div className="color-primary l-wrapper clearfix">
        <hr className="divider divider-socials l-full"></hr>
        <FooterSocialMedia />
        <FooterFormWrapper />
      </div>
      <div className="color-primary l-wrapper clearfix">
        <hr className="divider divider-locations d-block l-full"></hr>
        <FooterCompanyLocations />
      </div>
      <div className="l-wrapper l-with-gutter-top-mobile l-with-gutter-bottom-mobile clearfix">
        <FooterLawLinks showCookiesForm={showCookiesForm} />
      </div>
    </div>
  </footer>
);
