import React, { FC } from "react";
import { useMobileDetect } from "@/hooks/useMobileDetect";

export interface SiteFooterWrapperProps {
  showOnMobile?: boolean;
}

export const SiteFooterWrapper: FC<SiteFooterWrapperProps> = ({
  children,
  showOnMobile = false,
}) => {
  const { isMobile } = useMobileDetect(640);

  return (
    <>
      {(showOnMobile || !isMobile) && (
        <div className="fixed-action site-footer-buttons">
          <div className="fixed-action-buttons align-end l-half-push-after l-half-push-bottom l-bleed">
            {children}
          </div>
        </div>
      )}
    </>
  );
};
