import React, { FC, useState } from "react";
import {
  HeaderNavigationConfigData,
  HeaderNavigationConfig,
} from "@/components/helpers/Header.helper";

interface HeaderProps {
  pageName?: string;
}

const HeaderNavigation: FC<HeaderProps> = ({ pageName }) => {
  return (
    <>
      {HeaderNavigationConfigData?.map(
        (page: HeaderNavigationConfig, index: number) => (
          <li
            key={index}
            className={
              "site-nav-item d-flex-box flex-align-center position-rel " +
              page.url
            }
          >
            <a
              href={"/" + page.url}
              className={page?.className}
              style={{ fontSize: "1em" }}
            >
              {pageName?.includes(page.url) ? (
                <h1 className="page-title" style={{ fontSize: "1em" }}>
                  {page.pageTitle}
                </h1>
              ) : (
                page.pageTitle
              )}
            </a>
          </li>
        )
      )}
    </>
  );
};

export const Header: FC<HeaderProps> = ({ pageName }) => {
  const [showNavMobile, setShowNavMobile] = useState(false);

  return (
    <header className="site-header color-bg-negative" role="masthead">
      <div className="l-wrapper clearfix" style={{ position: "relative" }}>
        <div className="site-header-logo">
          <div className="image-object">
            <a href="/#intro">
              <img alt="ANIXE logo" src="/images/logo_dark.svg" />
            </a>
          </div>
        </div>
        <div className="l-last text-1 text-upcase align-right site-nav-links">
          <button
            onClick={() => setShowNavMobile(!showNavMobile)}
            className="button button-transparent hidden-desktop color-negative text-1-5 site-nav-button"
          >
            <img width="40" alt="Menu Icon" src="/images/bars.svg" />
          </button>
          <nav className={`${!showNavMobile && "hidden-mobile hidden-tablet"}`}>
            <ul className="site-nav d-flex-box flex-dir-column">
              {<HeaderNavigation pageName={pageName} />}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};
