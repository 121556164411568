export interface HeaderNavigationConfig {
  url: string;
  pageTitle: string;
  className?: string;
}

export const HeaderNavigationConfigData: HeaderNavigationConfig[] = [
  {
    url: "travel-industry-solutions",
    pageTitle: "Travel Industry Solutions",
  },
  { url: "professional-services", pageTitle: "Professional Services" },
  { url: "jobs", pageTitle: "Career" },
  { url: "blog", pageTitle: "Blog" },
  { url: "suppliers", pageTitle: "Suppliers" },
  {
    url: "contact",
    pageTitle: "Contact us",
    className: "button button-ghost-quaternary inherit-font",
  },
];
