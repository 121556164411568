import React, { FC, useEffect, useState } from "react";
import { setCookiesConsent } from "@/utils/cookieConsent";
import { getCookie } from "@/utils/cookies";
import { StorageName } from "@/constants/storageName";
import { Checkbox } from "@/components/basic/Checkbox";
import { useCheckboxes } from "@/hooks/useCheckboxes";
import { activateGoogleScripts } from "@/utils/googleScripts";
import { isSSR } from "@/utils/isSSR";

const FirstStep: React.FC<{ onApprove: () => void; showMore: () => void }> = ({
  onApprove,
  showMore,
}) => (
  <>
    <div id="first-step-info">
      <div className="flex-box flex-box-space-between flex-align-center">
        <b className="cookies-title">Use of cookies</b>
        <div className="anixe-logo-frame">
          <img
            className="anixe-logo centred-icon"
            alt="ANIXE logo"
            src="/images/logo_white.png"
          />
        </div>
      </div>
      <div className="decor-line"></div>
      <div>
        <p>
          We (ANIXE Polska) would like to set optional analytics and retargeting
          cookies on your device to help us improve our website (based on
          reports and statistics about how you use our website) and improve our
          marketing activities (based on tracking your visits on our website).
          The cookies we use are provided by Google LLC seated in USA. The
          information that you have visited this website may be shared with
          third-party vendors, including Google.
        </p>
        <p>
          Do you agree? Your consent is voluntary and may be withdrawn at any
          time with the effect forthe future. Click on „More Info” button to
          choose your own cookies’ settings and learn more.
        </p>
      </div>
    </div>
    <div className="first-step-buttons flex-box flex-box-space-between flex-align-center">
      <a
        onClick={onApprove}
        aria-label="dismiss cookie message"
        tabIndex={0}
        className="cc-btn cc-dismiss"
      >
        AGREE AND PROCEED
      </a>
      <a onClick={showMore} tabIndex={0} className="more-info-link">
        More info
      </a>
    </div>
  </>
);

interface SecondStepProps {
  onApprove: (allowAnalytics: boolean, allowRetargetting: boolean) => void;
  allowAnalytics?: boolean;
  allowRetargetting?: boolean;
}
export const SecondStep: FC<SecondStepProps> = ({
  onApprove,
  allowAnalytics,
  allowRetargetting,
}) => {
  const [checkboxesState, changeCheckboxState] = useCheckboxes({
    analyticsCheckbox: allowAnalytics ?? true,
    retargettingCheckbox: allowRetargetting ?? true,
  });

  return (
    <>
      <div id="second-step-info">
        <div className="flex-box flex-box-space-between flex-align-center">
          <b className="cookies-title">Cookie preferences</b>
          <div className="anixe-logo-frame">
            <img
              className="anixe-logo centred-icon"
              alt="ANIXE logo"
              src="/images/logo_white.png"
            />
          </div>
        </div>
        <div className="decor-line"></div>
        <div className="flex-grid-20-80">
          <div>
            <Checkbox
              name="analyticsCheckbox"
              checked={checkboxesState["analyticsCheckbox"]}
              callback={() => changeCheckboxState("analyticsCheckbox")}
            />
          </div>
          <div className="l-with-half-gutter-bottom-mobile l-10-cols-tablet l-12-cols-mobile">
            <b>Analytics cookies</b>
            <p>
              We (ANIXE Polska) would like to set Google Analytics cookies on
              your device to help us to improve our website by collecting and
              reporting information on how you use it. The cookies collect
              information in a way that does not directly identify anyone. The
              period of activity of these cookies is up to 2 years.
            </p>
          </div>
        </div>
        <div className="flex-grid-20-80">
          <div>
            <Checkbox
              name="retargettingCheckbox"
              checked={checkboxesState["retargettingCheckbox"]}
              callback={() => changeCheckboxState("retargettingCheckbox")}
            />
          </div>
          <div className="l-with-half-gutter-bottom-mobile l-10-cols-tablet l-12-cols-mobile">
            <b>Retargeting cookies</b>
            <p>
              We (ANIXE Polska) would like to set Google Ads cookies on your
              device to help us to improve our marketing activities. These
              cookies are used to display ads better tailored to your interests.
              These cookies remember that you visited our website and share this
              information with third-party vendors, including Google to serve
              you ads based on your past visits to our website. These are
              session cookies. Web browsers normally delete session cookies when
              the user closes the browser.
            </p>
          </div>
        </div>
      </div>
      <div
        id="second-step-buttons"
        className="close-elements-in-row l-with-dbl-gutter-top-mobile"
      >
        <div className="l-push-after" style={{ height: "45px" }}>
          <a
            onClick={() => onApprove(true, true)}
            aria-label="dismiss cookie message"
            tabIndex={0}
            className="cc-btn cc-dismiss"
          >
            ACCEPT ALL
          </a>
        </div>
        <div style={{ height: "45px" }}>
          <a
            onClick={() =>
              onApprove(
                checkboxesState["analyticsCheckbox"],
                checkboxesState["retargettingCheckbox"]
              )
            }
            aria-label="dismiss cookie message"
            tabIndex={0}
            className="cc-btn cc-dismiss"
          >
            Save and close
          </a>
        </div>
      </div>
    </>
  );
};

export interface CookieConsentProps {
  showSecondStep: boolean;
  onClose: () => void;
}

export const CookieConsent: FC<CookieConsentProps> = ({
  showSecondStep,
  onClose,
}) => {
  const [showCookiesBanner, setShowCookiesBanner] = useState(false);
  const [showMore, setShowMore] = useState(false);
  useEffect(() => {
    if (!getCookie(StorageName.cookieConsentStatus)) {
      setShowCookiesBanner(true);
    }
    document.body.classList.toggle("cookies", showCookiesBanner);
  }, [showCookiesBanner]);

  const approveCallback = (showAnalyticsCookies, showRetargettingCookies) => {
    setCookiesConsent(showAnalyticsCookies, showRetargettingCookies);
    setShowCookiesBanner(false);
    activateGoogleScripts();
    onClose();
  };

  const allowAnalytics =
    !isSSR() &&
    localStorage.getItem(StorageName.analytics) &&
    localStorage.getItem(StorageName.analytics) === "true";

  const allowRetargetting =
    !isSSR() &&
    localStorage.getItem(StorageName.retargeting) &&
    localStorage.getItem(StorageName.retargeting) === "true";

  return (
    (showCookiesBanner || showSecondStep) && (
      <div className="fixed-action z-overlay">
        <div id="cookie-consent">
          <div
            role="dialog"
            aria-live="polite"
            aria-label="cookieconsent"
            aria-describedby="cookieconsent:desc"
            className="cc-window cc-banner cc-type-info cc-theme-block cc-bottom cc-color-override--1351869031 "
          >
            <div className="display-column l-with-dbl-side-gutters-mobile-cookie-consent">
              <div className="scrollable-text" id="scrollableDiv">
                {showMore || showSecondStep ? (
                  <SecondStep
                    onApprove={approveCallback}
                    allowAnalytics={allowAnalytics}
                    allowRetargetting={allowRetargetting}
                  />
                ) : (
                  <FirstStep
                    onApprove={() => approveCallback(true, true)}
                    showMore={() => setShowMore(true)}
                  />
                )}
                <p>
                  For more information about the cookies we use please see also
                  our&nbsp;
                  <b>
                    <a
                      aria-label="learn more about cookies"
                      tabIndex={0}
                      className="cookie-consent-link"
                      href="https://anixe.pl/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy and Cookie Policy
                    </a>
                  </b>
                  . To see our <b>Terms of Service</b> click&nbsp;
                  <b>
                    <a
                      aria-label="learn more about cookies"
                      tabIndex={0}
                      className="cookie-consent-link"
                      href="https://anixe.pl/terms-of-service"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                  </b>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
