import React, { FC } from "react";
import { MailingListTooltip } from "@/components/MailingListTooltip";
import { Input } from "@/components/basic/Input";

export interface MailingListCheckboxProps {
  handleChange: () => void;
  required?: boolean;
  withLineBreak?: boolean;
}

export const MailingListCheckbox: FC<MailingListCheckboxProps> = ({
  handleChange,
  required,
  withLineBreak,
}) => (
  <>
    <Input
      type="checkbox"
      name="mailingList"
      handleChange={handleChange}
      value="on"
    />
    <span className={required && "required"}>Yes</span>, I want to receive from
    ANIXE Polska Sp. z o.o. information and offers to the above e-mail address
    {withLineBreak && <br />} (e.g. ANIXE’s Newsletter). Add me to your mailing
    list. <MailingListTooltip />
  </>
);
