import React, { FC, useEffect, useState } from "react";
import { Header } from "@/components/layout/Header";
import { Footer } from "@/components/layout/Footer";
import { SocialMedia } from "@/components/SocialMedia";
import { CookieConsent } from "@/components/layout/CookieConsent";
import { activateGoogleScripts } from "@/utils/googleScripts";
import Helmet from "react-helmet";

interface MainLayoutProps {
  pageName?: string;
}

export const MainLayout: FC<MainLayoutProps> = ({ children, pageName }) => {
  useEffect(() => {
    activateGoogleScripts();
  }, []);

  const [showCookiesSettings, setShowCookiesSettings] = useState(false);
  return (
    <>
      <Helmet>
        <body className="ltr " />
      </Helmet>
      <Header pageName={pageName} />
      <main>{children}</main>
      <SocialMedia />
      <CookieConsent
        onClose={() => setShowCookiesSettings(false)}
        showSecondStep={showCookiesSettings}
      />
      <Footer showCookiesForm={() => setShowCookiesSettings(true)} />
    </>
  );
};
