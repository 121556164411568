import React, { FC, ChangeEvent, MouseEvent } from "react";
import { Input } from "@/components/basic/Input";
import { MailingListCheckbox } from "@/components/MailingListCheckbox";
import { MailingListTooltip } from "./MailingListTooltip";

export interface FooterFormProps {
  submit: (event: MouseEvent<HTMLButtonElement>) => void;
  handleEmailInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleMailingListCheckboxChange: () => void;
  handleEmailInputClick: () => void;
  email: string;
  disabled: boolean;
  showMarketingAgreements: boolean;
}

export const FooterForm: FC<FooterFormProps> = ({
  submit,
  handleEmailInputChange,
  disabled,
  handleMailingListCheckboxChange,
  handleEmailInputClick,
  showMarketingAgreements,
  email,
}) => {
  return (
    <form className="footer-newsletter l-8-cols-desktop ltr">
      <Input
        name="email-address"
        classname="newsletter-input"
        handleChange={handleEmailInputChange}
        placeholder="Enter email address"
        handleClick={handleEmailInputClick}
        value={email}
      />
      <button
        className="button button-ghost-primary v-align-top l-push-top-mobile"
        type="submit"
        name="subscribe"
        onClick={submit}
        disabled={disabled}
      >
        subscribe to newsletter
      </button>
      {showMarketingAgreements && (
        <div className="text-12 l-with-half-gutter-top-mobile">
          Yes, I want to receive from ANIXE Polska Sp. z o.o. marketing
          information in the form of a Newsletter.
          <br /> For this purpose, I give my e-mail address above.{" "}
          <MailingListTooltip />
          <div className="l-half-push-top l-bleed">
            <MailingListCheckbox
              handleChange={handleMailingListCheckboxChange}
              withLineBreak
              required
            />
          </div>
        </div>
      )}
    </form>
  );
};
