import React, { FC } from "react";

export const Tooltip: FC = ({ children }) => (
  <div className="tooltip">
    [More info]
    <div className="top" style={{ transform: "translate(15px)" }}>
      {children}
    </div>
  </div>
);
