import React, { FC } from "react";

export interface CheckboxProps {
  name: string;
  checked: boolean;
  callback: (checked: boolean) => void;
}

export const Checkbox: FC<CheckboxProps> = ({ name, checked, callback }) => {
  return (
    <div className="onoffswitch">
      <input
        type="checkbox"
        checked={checked}
        name={name}
        className="onoffswitch-checkbox"
        id={name}
        onChange={(e) => callback(e.target.checked)}
      />
      <label className="onoffswitch-label" htmlFor={name}>
        <span className="onoffswitch-inner"></span>
        <span className="onoffswitch-switch"></span>
      </label>
    </div>
  );
};
