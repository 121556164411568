import React, { FC } from "react";
import { Portal } from "@/components/basic/Portal";

export interface PopupProps {
  title: string;
  messages: string[];
  isOpen: boolean;
  showIcon?: boolean;
  handleClose: () => void;
  variant: "success" | "failure";
}

export const Popup: FC<PopupProps> = ({
  title,
  messages,
  showIcon = true,
  handleClose,
  isOpen,
  variant,
}) =>
  isOpen && (
    <Portal>
      <div className={`notice pos-rel ${variant}`}>
        <div className="l-wrapper">
          <div>
            <div className="inline-table l-full-mobile l-bleed">
              {showIcon && (
                <div className="inline-table-item inline-table-item-short l-with-dbl-gutter-after-mobile v-align-top">
                  <img
                    width="48"
                    height="48"
                    src="/images/icons/check.svg"
                    alt="checkmark"
                  />
                </div>
              )}
              <div className="inline-table-item v-align-top">
                <h3>{title}</h3>
                {messages.map((message) => (
                  <p key={message}>{message}</p>
                ))}
              </div>
              <div
                onClick={handleClose}
                className="inline-table-item v-align-top align-end clickable-element"
              >
                <strong>╳ CLOSE</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
