import React, { FC, ChangeEvent } from "react";

export interface InputProps {
  accept?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  name: string;
  handleChange?: (event: ChangeEvent) => void;
  classname?: string;
  value?: string;
  handleClick?: () => void;
}

export const Input: FC<InputProps> = ({
  accept,
  label,
  type = "text",
  placeholder,
  name,
  handleChange,
  handleClick,
  value,
  classname,
}) => (
  <>
    {!!label && <label htmlFor={name}>{label}</label>}
    <input
      accept={accept}
      className={classname}
      id={name}
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={handleChange}
      onClick={handleClick}
      value={value}
    />
  </>
);
