import { useState } from "react";

export type CheckboxesState = Record<string, boolean>;

export const useCheckboxes = (
  checkboxesState: CheckboxesState
): [CheckboxesState, (name: string) => void] => {
  const [state, setState] = useState<CheckboxesState>(checkboxesState);

  const changeCheckboxState = (name: string) => {
    if (!Object.prototype.hasOwnProperty.call(state, name)) return;

    const newState = { ...state, [name]: !state[name] };
    setState(newState);
  };

  return [state, changeCheckboxState];
};
