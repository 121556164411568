import React, { FC } from "react";
import { SocialMediaItem } from "@/components/basic/SocialMediaItem";
import { socialsPoland } from "@/utils/companySocialMedia";

export const SocialMedia: FC = () => (
  <ul className="sm-links">
    <SocialMediaItem
      classname="sm-links-item d-block"
      alt="facebook"
      url={socialsPoland.facebook}
      iconUrl="/images/social/fb_gray.svg"
      iconWidth="36"
      iconHeight="36"
    />
    <SocialMediaItem
      classname="sm-links-item d-block"
      alt="twitter"
      url={socialsPoland.twitter}
      iconUrl="/images/social/twitter_gray.svg"
      iconWidth="36"
      iconHeight="36"
    />
    <SocialMediaItem
      classname="sm-links-item d-block"
      alt="linkedin"
      url={socialsPoland.linkedin}
      iconUrl="/images/social/linkedin_gray.svg"
      iconWidth="36"
      iconHeight="36"
    />
  </ul>
);
