import { getCookie } from "@/utils/cookies";
import { StorageName } from "@/constants/storageName";
// eslint-disable

export function showLocalStorage(name) {
  if (localStorage.getItem(name) !== null) {
    return localStorage.getItem(name) === "true";
  }
  return true;
}

function gtag(...params) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(params);
}

export function activateGoogleScripts() {
  if (getCookie(StorageName.cookieConsentStatus)) {
    if (showLocalStorage(StorageName.analytics)) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-WNLRTSR");
    }
    if (showLocalStorage(StorageName.retargeting)) {
      gtag("js", new Date());
      gtag("config", "AW-732512319");

      const script = document.createElement("script");
      script.async = true;
      script.src = "https://www.googletagmanager.com/gtag/js?id=AW-732512319";
      document.getElementsByTagName("head")[0].appendChild(script);
    }
  }
}
