export const successTitle = "Thank you";
export const failureTitle = "Something went wrong";

export const successEmailMessage =
  "Please check your inbox. E-mail with the link to the product card should be already there.";
export const successNewsletterMessage =
  "Your subscription to our mailing list has been confirmed.";

export const failureEmailMessage =
  "Please enter your email again to get the link to the product card.";
export const failureNewsletterMessage =
  "Please click the box below and enter your email again to subscribe to our newsletter.";
export const footerFailureNewsletterMessage =
  "Please enter your email again to subscribe to our newsletter.";
