import { StorageName } from "@/constants/storageName";
import { setCookie } from "@/utils/cookies";

export const setCookiesConsent = (
  allowAnalyticsCookies: boolean,
  allowRetargettingCookies
): void => {
  setCookie(StorageName.cookieConsentStatus, "dismiss", 365);
  localStorage.setItem(StorageName.analytics, String(allowAnalyticsCookies));
  localStorage.setItem(
    StorageName.retargeting,
    String(allowRetargettingCookies)
  );
};
